import React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { useSpring, animated } from 'react-spring';

import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

import white_chevron from "../assets/images/white_chevron.svg";
import ShareButtons from "../components/ShareButtons";
import { PageLinkList } from "../components/PageLinkList";

const FormWrapperTemplate = ({ data, pageContext, location }) => {
    const [isFormOpen, setFormOpen] = React.useState(false);
    const toggleForm = () => setFormOpen(open => !open);
    const spring = useSpring({
        paddingBottom: "50px",
        opacity: isFormOpen ? 1 : 0,
        transform: isFormOpen ? "translateY(0px)" : "translateY(1000px)"
    });

    const {
        speakersTitle,
        speakers,
        overviewTitle,
        overviewText,
        coverImage,
        coverImageAltText,
        coverImageBackgroundColor,
        formIframeTag,
        createdAt,
        updatedAt,
        metaTags,
        pageLinkLists
    } = data.contentfulFormWrapper;

    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        author: speakers[0].name,
        coverImage: coverImage && coverImage.file.url,
        url: location.pathName,
        metaTags: metaTags,
    }

    return (
        <Layout 
            title={pageContext.title} 
            pageContentId="google-forms-content"
            isWebinar={true}
            transparentNavigation={true}
            seoData={seoData}
            location={location}
        >
            <div className="mobile-platform-catalogue-container is-hidden-tablet">
                <animated.div style={spring} className="platform-menu webinar">
                    <div dangerouslySetInnerHTML={{ __html: formIframeTag.childMarkdownRemark.html }} />
                </animated.div>
                
                <nav className="navbar mobile-platform-catalogue webinar" onClick={toggleForm} role="navigation">
                    <div className="navbar-item">
                        <p className="is-white size-24">Fill the form</p>
                    </div>
                    <div className="navbar-item chevron">
                        <img src={white_chevron} alt="" style={{ verticalAlign: "middle", transform: isFormOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "all .5s ease" }} />
                    </div>
                </nav>
            </div>

            <main id="google-forms-content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-hidden-mobile" style={{ backgroundColor: `${coverImageBackgroundColor}` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered is-justify-content-center">
                                <h1 className="is-sr-only">
                                    {pageContext.title}
                                </h1>

                                <div className="column is-8">
                                    <img src={coverImage.file.url} alt={{coverImageAltText}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="hero is-large is-hidden-tablet" style={{ backgroundColor: `${coverImageBackgroundColor}` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered is-justify-content-center">
                                <h1 className="is-sr-only">
                                    {pageContext.title}
                                </h1>

                                <div className="column is-8">
                                    <img src={coverImage.file.url} alt={{coverImageAltText}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-grey">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    {speakersTitle}
                                </h2>

                                {speakers.map((speaker, i) => (
                                    <div className="columns is-vcentered is-mobile is-multiline" key={i}>
                                        {speaker.linkedInPage ? (
                                            <>
                                                <div className="column is-2">
                                                    <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                </div>
                                                <div className="column is-10">
                                                    <a href={speaker.linkedInPage} target="_blank">
                                                        <span className="is-black size-16">{speaker.name}</span><br />
                                                        <span className="is-grey-1 size-14">{speaker.title}</span>
                                                    </a>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="column is-2">
                                                    <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                </div>
                                                <div className="column is-10">
                                                    <span className="is-black size-16">{speaker.name}</span><br />
                                                    <span className="is-grey-1 size-14">{speaker.title}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}

                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    {overviewTitle}
                                </h2>

                                <div className="content" dangerouslySetInnerHTML={{ __html: overviewText.childMarkdownRemark.html }} />
                                <ShareButtons url={location.href} title={`Check out: ${pageContext.title}`} />
                                <Button textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
                            </div>

                            <div className="column is-hidden-mobile">
                                <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                                    <div className="card-content" dangerouslySetInnerHTML={{ __html: formIframeTag.childMarkdownRemark.html }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {pageLinkLists !== null && (
                    <section className={`section is-medium`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </main>
        </Layout>
    );
}

export default FormWrapperTemplate;

export const query = graphql`
query ($contentful_id: String!) {
    contentfulFormWrapper (contentful_id: { eq: $contentful_id }) {
        metaTags
        createdAt
        updatedAt
        coverImage {
          file {
              url
          }
          gatsbyImageData(width: 1920, quality: 100)
        }
        coverImageAltText
        coverImageBackgroundColor
        speakersTitle
        speakers {
          title
          name
          linkedInPage
          portrait {
              gatsbyImageData(width: 90, quality: 100)
          }
        }
        overviewTitle
        overviewText {
            childMarkdownRemark {
                html
            }
        }
        formIframeTag {
            childMarkdownRemark {
                html
            }
        }
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
            title
        }
    }
}
`
